'use client'
import { type FC, memo, useState, useMemo } from 'react'
import styled from 'styled-components'
import { rgbAlpha, font13_13 } from '@/styles'

type tabType = {
  text: string,
  onClick?: any
}

type Props = {
  tabs: tabType[],
  bgColor?: string,
  filterTaskType?: string
  onTabClick?: (idx: number, text: string) => void;
}

const getTabs = (tabs, clickedTab, handleClick, bgColor) =>
  tabs.map((tab, idx) => (
    <Tab
      $bgColor={bgColor}
      key={idx}
      onClick={() => {
        tab.onClick && tab.onClick()
        handleClick(idx, tab.text)
      }}
      className={clickedTab === idx ? 'active' : ''}
    >
      {tab.text}
    </Tab>
  ))


export const Tabs: FC<Props> = memo(({ tabs, bgColor, filterTaskType, onTabClick }) => {
  const [clickedTab, setClickedTab] = useState(
    tabs.findIndex(obj => obj.text.toLowerCase() === filterTaskType)
  )

  const handleClick = (idxTab, text) => {
    const nextTab = clickedTab === idxTab ? -1 : idxTab
    setClickedTab(nextTab)
    if (tabs[nextTab].onClick) tabs[nextTab].onClick()
    else onTabClick && onTabClick(nextTab, text)
  }

  const TabsList = useMemo(() => getTabs(tabs, clickedTab, handleClick, bgColor), [bgColor, clickedTab, tabs])

  return (
    <Div $bgColor={bgColor}>
      {TabsList}
    </Div>
  )
})


export const Div = styled.ul<{$bgColor}>`
  align-items: flex-start;
  background: ${({ $bgColor }) => $bgColor ? rgbAlpha(`--color-${$bgColor}`, .3) :  rgbAlpha('--color-purple', .3)};
  border-radius: 30px;
  height: 40px;
  display: inline-flex;
`

export const Tab = styled.li<{$bgColor}>`
  ${font13_13(true, 400)}
  align-items: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
  /* max-width: 95px; */
  padding: 10px 30px;
  width: 100%;

  &.active {
    ${font13_13(true, 700)}
    background: ${rgbAlpha('--color-purple', .5)};
  }
`
