'use client'
import React, { type FC, memo, useCallback, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Figure } from '@/components'
import { useDates } from '@/hooks'
import moment from 'moment'
import { mq, vw, rgbAlpha, font13_18,font16_20 } from '@/styles'
import { Tabs } from '@/components/Tabs'

type Props = {
  type: 'tasks' | 'performance'
}

const getArrowIcon = (direction) => {
  return {
    type: 'image' as const,
    alt: 'Arrow icon',
    default: {
      src: `/images/svg/i--chevron-${direction}.svg`,
      size: { width: 6, height: 9 }
    }
  }
}

export const MinifiedCalendar: FC<Props> = memo(({ type }) => {
  const { setFilterTaskType, rangeWeek, updateWeek, updateMonth, updateQuarter, updateYear, updateCustomFirstDay, updateCustomSecondDay, filterTaskType, updateDay, currentDay, setFilterPerformanceType, filterPerformanceType, currentMonth, quarterRange, currentYear, customFirstDay, customSecondDay, currentQuarter, currentWeek } = useDates()
  const [view, setView] = useState(null)
  const [viewCustomFirstDay, setViewCustomFirstDay] = useState(null)
  const [viewCustomSecondDay, setViewCustomSecondDay] = useState(null)
  const [ , setOnclick] = useState(()=>{})
  const [ , setOnclickCustomFirstDay] = useState(()=>{})
  const [ , setOnclickCustomSecondDay] = useState(()=>{})
  const [disabled, setDisabled] = useState(true)
  const [disabledCustomSecond, setDisabledCustomSecond] = useState(false)

  const filterTasksBy = useCallback((filterByType) => {
    setFilterTaskType(filterByType)
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilterTaskType, filterTaskType])
  
  const getPerformanceRange = useCallback((filterPerformanceBy, click, idx?:string) => {
    switch (filterPerformanceBy) {
      case 'month':
        setOnclick(updateMonth(click))
        break
      case 'quarter':
        setOnclick(updateQuarter(click))
        break
      case 'year':
        setOnclick(updateYear(click))
        break
      case 'custom':
        idx === 'first' ? setOnclickCustomFirstDay(updateCustomFirstDay(click)) : setOnclickCustomSecondDay(updateCustomSecondDay(click))
        break
      default:
        setOnclick(updateWeek(click))
    }  
  } , [updateCustomFirstDay, updateCustomSecondDay, updateMonth, updateQuarter, updateWeek, updateYear])

  useEffect(() => {
    switch (filterPerformanceType) {
      case 'month':
        setDisabled(moment().month() !== currentMonth.month() ) 
        setView(moment(currentMonth).format('MMM YYYY'))
        break
      case 'quarter':
        setDisabled( (moment().quarter() === currentQuarter) && (moment().year() === currentYear) )
        setView(quarterRange)
        break
      case 'year':
        setDisabled((moment().year() === currentYear)) 
        setView(currentYear)
        break
      case 'custom':
        if(moment().day() === moment(customFirstDay).day() && (moment().month() === moment(customFirstDay).month()) ) {
          setDisabled(true)  
        }
        else if(moment().day() === moment(customSecondDay).day() && moment().month() === moment(customSecondDay).month()) {
          setDisabledCustomSecond(true)
        } else {
          setDisabled(false)
          setDisabledCustomSecond(false)
        }
        setViewCustomFirstDay(customFirstDay)
        setViewCustomSecondDay(customSecondDay)
        break
      default:
        setDisabled((moment().week() === currentWeek) &&  (moment().year() === currentYear)) 
        setView(rangeWeek)
    }  
  }, [rangeWeek, filterPerformanceType, currentMonth, quarterRange, currentYear, currentDay, customFirstDay, customSecondDay, currentQuarter, currentWeek])


  useEffect(() => {
    setDisabled((moment().format('ddd, D MMM YYYY') === currentDay))
  },[currentDay])

  return (
    <>
      {type === 'tasks' &&
        <Calendar $type={'tasks'}>
          <Tabs tabs={[
            { text: 'Day', onClick: () => filterTasksBy('day') }, 
            { text: 'Week', onClick: () => filterTasksBy('week') }
          ]} filterTaskType={filterTaskType}></Tabs> 
          {filterTaskType === 'week' ? 
            <div>
              <button className='fig_container' onClick={() => { updateWeek('prev')} }>
                <Figure media={getArrowIcon('left')} />
              </button>
              <span>{rangeWeek}</span>
              <button className='fig_container'  disabled={disabled} onClick={() => { 
                if(disabled) return
                updateWeek('next')} }>
                <Figure media={getArrowIcon('right')} className={disabled ? 'disabled': ''} />
              </button>
            </div>
            :
            <div>
              <button className='fig_container' onClick={() => { updateDay('prev')} }>
                <Figure media={getArrowIcon('left')}/>
              </button>
              <span>{moment(new Date(currentDay)).format('ddd D.M.YY')}</span>
              <button className='fig_container' disabled={disabled}  onClick={() => { 
                if(disabled) return
                updateDay('next')}
              }>
                <Figure media={getArrowIcon('right')} className={disabled ? 'disabled': ''} />
              </button>
            </div>
          }
        </Calendar >
      }
      {type === 'performance' &&
        <Calendar $type={'performance'}>
          {filterPerformanceType === 'custom' ?
            <div className='custom'>
              <button className='fig_container' onClick={() => { getPerformanceRange(filterPerformanceType, 'prev', 'first')} }>
                <Figure media={getArrowIcon('left')}/>
              </button>
              {/* <Figure media={getArrowIcon('left')} onClick={() => { getPerformanceRange(filterPerformanceType, 'prev', 'first')} }/> */}
              <span>{viewCustomFirstDay}</span>
              <button className='fig_container' disabled={disabled} onClick={() => { 
                if(disabled) return
                getPerformanceRange(filterPerformanceType, 'next', 'first')
              } }>
                <Figure media={getArrowIcon('right')} className={disabled ? 'disabled': ''} />
              </button>

              <button className='fig_container' onClick={() => { getPerformanceRange(filterPerformanceType, 'prev', 'second') }}>
                <Figure media={getArrowIcon('left')}/>
              </button>
              <span>{viewCustomSecondDay}</span>
              <button className='fig_container' disabled={disabled} onClick={() => { 
                if(disabledCustomSecond) return
                getPerformanceRange(filterPerformanceType, 'next', 'second')
              }}>
                <Figure media={getArrowIcon('right')} className={disabled ? 'disabled': ''} />
              </button>

            </div>
            :
            <div>
               <button className='fig_container'onClick={() => { getPerformanceRange(filterPerformanceType, 'prev')} }>
                <Figure media={getArrowIcon('left')}/>
              </button>
              <span>{view}</span>
              <button className='fig_container' disabled={disabled} onClick={() => { 
                if(disabled) return
                getPerformanceRange(filterPerformanceType, 'next')
              } }>
                <Figure media={getArrowIcon('right')} className={disabled ? 'disabled': ''} />
              </button>
            </div>
          }
          <Div>
            <button className={filterPerformanceType === 'week'? 'active' : ''} onClick={() => { setFilterPerformanceType('week')} }>Week</button>
            <button className={filterPerformanceType === 'month'? 'active' : ''} onClick={() => { setFilterPerformanceType('month')} }>Month</button>
            <button className={filterPerformanceType === 'quarter'? 'active' : ''} onClick={() => { setFilterPerformanceType('quarter')} }>Quarter</button>
            <button className={filterPerformanceType === 'year'? 'active' : ''} onClick={() => { setFilterPerformanceType('year')} }>Year</button>
            <button className={filterPerformanceType === 'custom'? 'active' : ''} onClick={() => { setFilterPerformanceType('custom')} }>Custom</button>
          </Div>
        </Calendar >
      }
    </>
  )
})

const performance = css`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 990px;
  width: 100%;

  div:first-child {
    align-items: center;
    background: rgba(255, 251, 251, 0.05);
    border-radius:30px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    height: 40px;
    justify-content: space-between;
    margin-top: 10px;
    padding: 5px 10px;

    
    ${mq.greaterThan('tablet')} {
      justify-content: flex-end;
      margin-top: 0;
    }

    button:disabled {
      opacity: .5;
      pointer-events: none;
      user-select: none;
    }

    .fig_container {
      align-items: center;
      border-radius: 30px;
      display: flex;
      gap: ${vw(10, 'mobile')};
      height: ${vw(30, 'mobile')};
      justify-content: center;
      padding: ${vw(8, 'mobile')};
      width: ${vw(30, 'mobile')};
      
      ${mq.greaterThan('nexus7')} {
        gap: ${vw(10, 'nexus7')};
        height: ${vw(30, 'nexus7')};
        padding: ${vw(8, 'nexus7')};
        width: ${vw(30, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        gap: ${vw(10, 'desktop')};
        height: ${vw(30, 'desktop')};
        padding: ${vw(8, 'desktop')};
        width: ${vw(30, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        gap: 10px;
        height: 30px;
        padding: 8px;
        width: 30px;
      }

      > figure {
        height: ${vw(9, 'mobile')};
        width: ${vw(6, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          height: ${vw(9, 'nexus7')};
          width: ${vw(6, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          height: ${vw(9, 'desktop')};
          width: ${vw(6, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          height: 9px;
          width: 6px;
        }

        &.disabled {
          opacity: .5;
          pointer-events: none;
          user-select: none;
        }
      }
    }

    > span {
      ${font16_20(false, 600)}
      text-align: center;
    
    }
  }

  div:last-child {
    align-items: center;
    background-color: ${rgbAlpha('--color-white', .02)};
    border-radius: ${vw(30, 'mobile')};
    display: flex;
    flex-direction: row;
    gap: ${vw(5, 'mobile')};
    max-width: ${vw(538, 'mobile')};
    padding: ${vw(5, 'mobile')};
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(30, 'nexus7')};
      gap: ${vw(5, 'nexus7')};
      max-width: ${vw(538, 'nexus7')};
      padding: ${vw(5, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(30, 'desktop')};
      gap: ${vw(5, 'desktop')};
      max-width: ${vw(538, 'desktop')};
      padding: ${vw(5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 30px;
      gap: 5px;
      max-width: 538px;
      padding: 5px;
    }

    > button {
      ${font13_18(false, 400)}
      align-items: center;
      border-radius: ${vw(20, 'mobile')};
      color: #FFF;
      display: flex;
      gap: ${vw(5, 'mobile')};
      justify-content: center;
      padding: ${vw(10, 'mobile')};
      min-width: ${vw(70, 'mobile')};
      width: 20%;

      ${mq.greaterThan('nexus7')} {
        border-radius: ${vw(20, 'nexus7')};
        gap: ${vw(5, 'nexus7')};
        padding: ${vw(10, 'nexus7')};
        min-width: ${vw(70, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        border-radius: ${vw(20, 'desktop')};
        gap: ${vw(5, 'desktop')};
        padding: ${vw(10, 'desktop')};
        min-width: ${vw(70, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        border-radius: 20px;
        gap: 5px;
        padding: 10px;
        min-width: 70px;
      }

      &.active {
        background-color: ${rgbAlpha('--color-purple', .5)};
      }
    }
  } 
`
const tasks = css`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 20px 0;

  ${mq.greaterThan('tablet')} {
    grid-template-columns: repeat(2, 1fr);
  }

  > ul {
    margin-top: 80px;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      margin-top: 0;
      width: 190px;
    }
  }

  > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: ${vw(310, 'mobile')};
    margin-top: 10px;

    ${mq.greaterThan('nexus7')} {
      min-width: ${vw(310, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      justify-content: flex-end;
      min-width: ${vw(310, 'desktop')};
      margin-top: 0;
    }

    ${mq.greaterThan('desktop')} {
      min-width: 310px;
    }

    button:disabled {
      opacity: .5;
      pointer-events: none;
      user-select: none;
    }

    .fig_container {
      align-items: center;
      background: rgba(255, 251, 251, 0.05);
      border-radius: 30px;
      display: flex;
      gap: ${vw(10, 'mobile')};
      height: ${vw(30, 'mobile')};
      justify-content: center;
      padding: ${vw(8, 'mobile')};
      width: ${vw(30, 'mobile')};
      
      ${mq.greaterThan('nexus7')} {
        gap: ${vw(10, 'nexus7')};
        height: ${vw(30, 'nexus7')};
        padding: ${vw(8, 'nexus7')};
        width: ${vw(30, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        gap: ${vw(10, 'desktop')};
        height: ${vw(30, 'desktop')};
        padding: ${vw(8, 'desktop')};
        width: ${vw(30, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        gap: 10px;
        height: 30px;
        padding: 8px;
        width: 30px;
      }

      > figure {
        height: ${vw(9, 'mobile')};
        width: ${vw(6, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          height: ${vw(9, 'nexus7')};
          width: ${vw(6, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          height: ${vw(9, 'desktop')};
          width: ${vw(6, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          height: 9px;
          width: 6px;
        }

        &.disabled {
          opacity: .5;
          pointer-events: none;
          user-select: none;
        }
      }
    }

    > span {
      ${font16_20(false, 600)}
      margin: 0 ${vw(30, 'mobile')};
      max-width: ${vw(110, 'mobile')};
      min-width: ${vw(100, 'mobile')};
      text-align: center;

      ${mq.greaterThan('nexus7')} {
        margin: 0 ${vw(30, 'nexus7')};
        max-width: ${vw(110, 'nexus7')};
        min-width: ${vw(100, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin: 0 ${vw(30, 'desktop')};
        max-width: ${vw(110, 'desktop')};
        min-width: ${vw(100, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin: 0 30px;
        max-width: 110px;
        min-width: 100px;
      }
    }
  }
`
export const Div = styled.div<any>`
  display: flex;
  flex-direction: row;
`


export const Calendar = styled.aside<{$type: 'tasks' | 'performance'}>`
  margin: 40px 0 20px !important;
  ${({ $type }) => $type === 'tasks' ? tasks : performance }
`

